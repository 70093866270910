/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import renderHTML from "react-render-html"

function SEO({ description, title, slug, ogImage }) {
  const desc = !description
    ? "We provide fee-only, comprehensive financial planning services to help individuals and young families across the nation prepare for tomorrow, starting today."
    : description

  const fullTitle = !title
    ? `2050 Capital - Financial Planning Services - South Carolina`
    : title

  const OG_IMAGE = !ogImage
    ? "https://clients.udioventures.com/twentyfiftycapital/wp-content/uploads/sites/10/2019/06/2050-ogimage.jpg"
    : ogImage

  const OG_IMAGE_HTTP = !ogImage
    ? "http://clients.udioventures.com/twentyfiftycapital/wp-content/uploads/sites/10/2019/06/2050-ogimage.jpg"
    : ogImage

  // const data = useStaticQuery(graphql`
  //   query HeaderQuery {
  //     meta: wordpressSiteMetadata(
  //       id: { eq: "6a8363cf-6370-5a27-9514-0ff9daa301de" }
  //     ) {
  //       id
  //       name
  //       description
  //     }
  //   }
  // `)

  return (
    <Helmet>
      {/* This site is optimized with the Yoast SEO plugin v11.1.1 - https://yoast.com/wordpress/plugins/seo/ */}
      <html lang="en" />
      <title>{renderHTML(fullTitle)}</title>
      <meta name="description" content={renderHTML(desc)} />
      <link rel="canonical" href={`https://twentyfiftycapital.com/${slug}`} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={renderHTML(fullTitle)} />
      <meta property="og:description" content={renderHTML(desc)} />
      <meta
        property="og:url"
        content={`https://twentyfiftycapital.com/${slug}`}
      />
      <meta property="og:site_name" content="2050 Capital" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/twentyfiftycapital/"
      />
      <meta property="og:image" content={OG_IMAGE_HTTP} />
      <meta property="og:image:secure_url" content={OG_IMAGE} />
      <meta property="og:image:width" content="768" />
      <meta property="og:image:height" content="512" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={renderHTML(desc)} />
      <meta name="twitter:title" content={renderHTML(fullTitle)} />
      <meta name="twitter:site" content="@twentyfiftycapital" />
      <meta name="twitter:image" content={OG_IMAGE} />
      <meta name="twitter:creator" content="@twentyfiftycapital" />
      <script
        type="application/ld+json"
        className="yoast-schema-graph yoast-schema-graph--main"
      >
        {`{"@context":"https://schema.org","@graph":[{"@type":"Organization","@id":"https://twentyfiftycapital.com/#organization","name":"","url":"https://twentyfiftycapital.com","sameAs":["https://www.facebook.com/twentyfiftycapital/","https://www.instagram.com/poirierchase/","https://www.linkedin.com/in/chase-poirier-60898096/","https://twitter.com/twentyfiftycapital"]},{"@type":"WebSite","@id":"https://twentyfiftycapital.com/#website","url":"https://twentyfiftycapital.com/","name":"Chase Poirier","publisher":{"@id":"https://twentyfiftycapital.com/#organization"},"potentialAction":{"@type":"SearchAction","target":"https://twentyfiftycapital.com/?s={search_term_string}","query-input":"required name=search_term_string"}},{"@type":"WebPage","@id":"https://twentyfiftycapital.com/${slug}#webpage","url":"https://twentyfiftycapital.com/${slug}","inLanguage":"en-US","name":"${renderHTML(
          fullTitle
        )}","isPartOf":{"@id":"https://twentyfiftycapital.com/#website"},"datePublished":"2019-05-08T02:42:07+00:00","dateModified":"2019-05-15T03:46:22+00:00","description":"${renderHTML(
          desc
        )}"}]}`}
      </script>
      {/* / Yoast SEO plugin. */}
    </Helmet>
  )
}

export default SEO
