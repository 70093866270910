import React from "react"
import "./button.css"
import { Link } from "gatsby"

const Button = ({ to, children, variant, ...rest }) => {
  return to ? (
    <Link to={to} {...rest} className={`btn btn--${variant}`}>
      {children}
    </Link>
  ) : (
    <div {...rest} className={`btn btn--${variant}`}>
      {children}
    </div>
  )
}

export default Button
