import React from "react"
import "./type.css"

const Typography = ({ children, variant, size, ...rest }) => {
  const renderTextSize = () => {
    switch (variant) {
      case "h1": {
        if (variant === "small") {
          return (
            <h1 {...rest} className="text__h1 text__h1--small">
              {children}
            </h1>
          )
        }
        return (
          <h1 {...rest} className="text__h1">
            {children}
          </h1>
        )
      }
      case "h2":
        return (
          <h2 {...rest} className="text__h2">
            {children}
          </h2>
        )
      case "h3":
        return (
          <h3 {...rest} className="text__h3">
            {children}
          </h3>
        )
      case "h4":
        return (
          <h4 {...rest} className="text__h4">
            {children}
          </h4>
        )
      case "body1":
        return (
          <p {...rest} className="text__body1">
            {children}
          </p>
        )
      case "body2":
        return (
          <p {...rest} className="text__body2">
            {children}
          </p>
        )
      case "body3":
        return (
          <p {...rest} className="text__body3">
            {children}
          </p>
        )
      default:
        return (
          <p {...rest} className="text__body2">
            {children}
          </p>
        )
    }
  }
  return renderTextSize()
}

export default Typography
