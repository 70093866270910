import React from "react"
import Typography from "../components/typography"
import classNames from "classnames"
import { FormContext } from "./Form"

const inputs02 = [
  {
    type: "text",
    name: "firstName",
    label: "First name",
    placeholder: "Mary",
    isHalf: true,
  },
  {
    type: "text",
    name: "lastName",
    label: "Last name",
    placeholder: "Poppins",
    isHalf: true,
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    placeholder: "mary-poppins@gmail.com",
  },
  {
    type: "phone",
    name: "phoneNumber",
    label: "Phone number",
    placeholder: "555-555-5555",
  },
]

const options01 = [
  "Not sure how to even get started",
  "Control my financial chaos",
  "Pay my loans but also enjoy my life",
  "Be debt-free and achieve my financial goals",
]

export const FormState01 = ({ onNext }) => {
  const { fields, onFieldChange } = React.useContext(FormContext)

  const handleChange = value => () => {
    onFieldChange("currentGoal", value)
    onNext()
  }

  return (
    <>
      <Typography variant="h2">
        Hey there! What are your current goals with your student loans?
      </Typography>
      <Typography
        style={{ textAlign: "center", marginBottom: 32 }}
        variant="body1"
      >
        Let’s get started in a couple easy steps
      </Typography>
      <div className="form__radios">
        {options01.map(option => (
          <div
            key={option}
            onClick={handleChange(option)}
            className={classNames("form__radio", {
              "form__radio--active": fields.currentGoal === option,
            })}
          >
            <Typography variant="body1">{option}</Typography>
          </div>
        ))}
      </div>
    </>
  )
}

export const FormState02 = ({ onNext }) => {
  const { fields, onFieldChange } = React.useContext(FormContext)

  const handleChange = e => {
    onFieldChange(e.currentTarget.name, e.currentTarget.value)
  }

  return (
    <>
      <Typography variant="h2">
        Let’s get the conversation started and pay off your loans.
      </Typography>
      <Typography
        style={{ textAlign: "center", marginBottom: 32 }}
        variant="body1"
      >
        Fill out some info so we can contact you
      </Typography>
      <div className="form__fields">
        {inputs02.map(input => (
          <div
            key={input.name}
            className={classNames("form__input", {
              "form__input--half": input.isHalf,
            })}
          >
            <div className="form__label">{input.label}</div>
            <input
              type={input.type}
              placeholder={input.placeholder}
              name={input.name}
              value={fields[input.name]}
              onChange={handleChange}
            />
          </div>
        ))}
      </div>
    </>
  )
}

const options03 = [
  "$10,000 to $20,000",
  "$20,000 to $50,000",
  "$50,000 to $80,000",
  "$80,000 +",
]

export const FormState03 = ({ onNext }) => {
  const { fields, onFieldChange } = React.useContext(FormContext)

  const handleChange = value => () => {
    onFieldChange("incomeRange", value)
    onNext()
  }

  return (
    <>
      <Typography variant="h2">
        First question: What is your income range?
      </Typography>
      <Typography
        style={{ textAlign: "center", marginBottom: 32 }}
        variant="body1"
      >
        A bit more info so we can get started with your plan
      </Typography>
      <div className="form__radios">
        {options03.map(option => (
          <div
            key={option}
            onClick={handleChange(option)}
            className={classNames("form__radio", {
              "form__radio--active": fields.incomeRange === option,
            })}
          >
            <Typography variant="body1">{option}</Typography>
          </div>
        ))}
      </div>
    </>
  )
}

const options04 = ["22 to 24", "25 to 28", "29 to 34", "35 +"]

export const FormState04 = ({ onNext }) => {
  const { fields, onFieldChange } = React.useContext(FormContext)

  const handleChange = value => () => {
    onFieldChange("ageRange", value)
    onNext()
  }

  return (
    <>
      <Typography variant="h2">
        Second question: What is your age range?
      </Typography>
      <Typography
        style={{ textAlign: "center", marginBottom: 32 }}
        variant="body1"
      >
        A bit more info so we can get started with your plan
      </Typography>
      <div className="form__radios">
        {options04.map(option => (
          <div
            key={option}
            onClick={handleChange(option)}
            className={classNames("form__radio", {
              "form__radio--active": fields.ageRange === option,
            })}
          >
            <Typography variant="body1">{option}</Typography>
          </div>
        ))}
      </div>
    </>
  )
}

const options05 = [
  "Less than $5,000",
  "$5,000 to $10,000",
  "$10,000 to $70,000",
  "$70,000 +",
]

export const FormState05 = () => {
  const { fields, onFieldChange } = React.useContext(FormContext)

  const handleChange = value => () => {
    onFieldChange("debtRange", value)
  }

  return (
    <>
      <Typography variant="h2">
        Last question: What is your average range of debt?
      </Typography>
      <Typography
        style={{ textAlign: "center", marginBottom: 32 }}
        variant="body1"
      >
        A bit more info so we can get started with your plan
      </Typography>
      <div className="form__radios">
        {options05.map(option => (
          <div
            key={option}
            onClick={handleChange(option)}
            className={classNames("form__radio", {
              "form__radio--active": fields.debtRange === option,
            })}
          >
            <Typography variant="body1">{option}</Typography>
          </div>
        ))}
      </div>
    </>
  )
}

export const FormState06 = () => {
  const {
    fields: { firstName },
  } = React.useContext(FormContext)

  return (
    <>
      <div className="form__done-img">
        <img
          src={require("../images/doc-illustration.png")}
          alt="document"
          className="done__img"
        />
        <img
          src={require("../images/form-bg.svg")}
          alt="swoosh"
          className="form__bg-img"
        />
      </div>
      <Typography variant="h2" style={{ marginBottom: 8 }}>
        Thanks, {firstName}! <span role="img">🤘</span>
      </Typography>
      <Typography
        style={{ textAlign: "center", marginBottom: 32 }}
        variant="body1"
      >
        We’ll be in touch within the next business day to start your plan
      </Typography>
    </>
  )
}
