import posed from 'react-pose'
// eslint-disable-next-line
import { circOut } from '@popmotion/easing'

// const CIRC_EASE = [0.645, 0.045, 0.355, 1]

export const TRANSITION_DELAY = 250

export const TransitionWrapper = posed.div({
  enter: {
    delay: TRANSITION_DELAY,
    beforeChildren: true,
    opacity: 1
  },
  exit: {
    opacity: 0,
    beforeChildren: true,
    transition: {
      default: { ease: 'easeInOut', duration: 200 }
    }
  }
})

export const ContentWrapper = posed.div({
  enter: {
    staggerChildren: 80
  },
  exit: {
    staggerChildren: 40,
    staggerDirection: -1
  }
})

export const BaseNoY = posed.div({
  enter: {
    opacity: 1,
    staggerChildren: 80,
    transition: {
      default: { duration: 300, delay: 40 }
    }
  },
  exit: {
    opacity: 0,
    staggerChildren: 80,
    staggerDirection: -1,
    transition: {
      default: { duration: 200, ease: 'easeInOut' }
    }
  }
})

export const Base = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    staggerChildren: 120,
    transition: {
      // y: { type: 'spring', stiffness: 400, damping: 30, mass: 1.6 },
      y: { duration: 500, ease: circOut },
      default: { duration: 300, delay: 40 }
    }
  },
  exit: {
    y: 24,
    opacity: 0,
    staggerChildren: 80,
    staggerDirection: -1,
    transition: {
      default: { duration: 200, ease: 'easeInOut' }
    }
  }
})
