import React from "react"

import Header from "./header"
import "./layout.css"
import "./theme.css"
import "../theme/block-theme.css"
import "../theme/blocks.css"

import Footer from "./footer"
import Form from "../form/Form"

export const LayoutContext = React.createContext({
  formOpen: false,
  toggleForm: function(toggle) {},
})

const Layout = ({ children }) => {
  const [form, setForm] = React.useState(false)

  return (
    <LayoutContext.Provider value={{ formOpen: form, toggleForm: setForm }}>
      <Header />
      <Form />

      <main>{children}</main>

      <Footer />
    </LayoutContext.Provider>
  )
}

export default Layout
