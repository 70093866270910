import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import Button from "./button"

const MobileLinks = ({ open, onClick }) => {
  const modalClasses = classNames({
    "mobile-links": true,
    "mobile-links--open": open,
  })
  return (
    <div>
      <div className={modalClasses}>
        <div className="mobile-links__container">
          {/* eslint-disable-next-line */}
          <ul onClick={() => onClick(!open)} className="mobile-links__list">
            <li className="mobile-links__item">
              <Link to="/how-it-works">How it works</Link>
            </li>
            <li className="mobile-links__item">
              <Link to="/work-with-us">Work with us</Link>
            </li>
            <li className="mobile-links__item">
              <Link to="/blog">Blog</Link>
            </li>
            <li className="mobile-links__item m-btn">
              <Button variant="primary" to="/schedule">
                Get Started
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MobileLinks
