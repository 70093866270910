import { useState } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import React from "react"
import Button from "./button"
import MobileLinks from "./MobileLinks"

import "./header.css"
import { LayoutContext } from "./layout"

const Header = ({ siteTitle }) => {
  const [mobileNav, setMobileNav] = useState(false)

  const { toggleForm } = React.useContext(LayoutContext)
  return (
    <nav className="nav">
      <div className="nav__container">
        <Link to="/">
          <img
            src={require("../images/logo.png")}
            alt="Logo"
            className="nav__logo"
          />
        </Link>
        <ul className="nav__links">
          <li className="nav__link">
            <Link to="/how-it-works" activeClassName="active" className="link">
              How it works
            </Link>
          </li>
          <li className="nav__link">
            <Link to="/work-with-us" activeClassName="active" className="link">
              Work with us
            </Link>
          </li>
          <li className="nav__link">
            <Link to="/blog" activeClassName="active" className="link">
              Blog
            </Link>
          </li>
          <li className="nav__link">
            <Button onClick={() => toggleForm(true)} variant="primary">
              Get Started
            </Button>
          </li>
        </ul>
        <div
          className={classNames("hamburger", {
            "hamburger--active": mobileNav,
          })}
          onClick={() => setMobileNav(!mobileNav)}
        >
          <div className="bun top" />
          <div className="patty" />
          <div className="bun bot" />
        </div>
      </div>
      <MobileLinks open={mobileNav} onClick={setMobileNav} />
    </nav>
  )
}

export default Header
