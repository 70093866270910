import React from "react"
import "./form.css"
import { LayoutContext } from "../components/layout"
import {
  FormState01,
  FormState02,
  FormState03,
  FormState06,
  FormState05,
  FormState04,
} from "./FormStates"
import Button from "../components/button"
import Typography from "../components/typography"

export const FormContext = React.createContext({
  fields: {},
  onFieldChange: function(name, value) {},
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Form = () => {
  const { formOpen, toggleForm } = React.useContext(LayoutContext)

  const [fields, setFields] = React.useState({
    currentGoal: "",
    phoneNumber: "",
    email: "",
    firstName: "",
    lastName: "",
    incomeRange: "",
    debtRange: "",
    ageRange: "",
  })

  const [formState, setFormState] = React.useState(1)
  const [shouldSubmit, setShouldSubmit] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState()

  React.useEffect(() => {
    if (shouldSubmit) {
      const button = document.querySelector("#inquire-netlify-form-submit")

      button.click()
    }
  }, [shouldSubmit])

  const handleSubmit = e => {
    e.preventDefault()

    setLoading(true)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Inquire", ...fields }),
    })
      .then(() => {
        setFormState(6)
        setLoading(false)
      })
      .catch(error => alert(error))
  }

  const onFieldChange = (name, value) => {
    setError()
    setFields({ ...fields, [name]: value })
  }

  const onNext = () => {
    const valid = onValidate()
    if (valid) {
      setFormState(formState + 1)
    }
  }

  const onValidNext = () => {
    if (formState + 1 === 6) {
      onSubmit()
    } else {
      setFormState(formState + 1)
    }
  }

  const onPrev = () => {
    setError()
    setFormState(formState - 1)
  }

  const onSubmit = lastValue => {
    if (lastValue) {
      setShouldSubmit(true)
    } else {
      setError("Please select an option")
    }
  }

  const onDone = () => {
    toggleForm(false)
  }
  const renderFormState = () => {
    switch (formState) {
      case 1:
        return <FormState01 onNext={onValidNext} />
      case 2:
        return <FormState02 />
      case 3:
        return <FormState03 onNext={onValidNext} />
      case 4:
        return <FormState04 onNext={onValidNext} />
      case 5:
        return <FormState05 />
      case 6:
        return <FormState06 />
      default:
        return null
    }
  }

  const showPrev = formState !== 1 && formState !== 6
  const showNext = formState !== 6 && formState !== 5
  const showSubmit = formState === 5
  const showDone = formState === 6

  const onValidate = () => {
    switch (formState) {
      case 1: {
        if (!fields.currentGoal) {
          setError("Please select an option")
          return false
        } else {
          setError()
          return true
        }
      }
      case 2: {
        if (
          fields.firstName &&
          fields.lastName &&
          fields.email &&
          fields.phoneNumber
        ) {
          setError()
          return true
        } else {
          setError("Please fill out all fields")
          return false
        }
      }
      case 3: {
        if (!fields.incomeRange) {
          setError("Please select an option")
          return false
        } else {
          setError()
          return true
        }
      }
      case 4: {
        if (!fields.ageRange) {
          setError("Please select an option")
          return false
        } else {
          setError()
          return true
        }
      }
      case 5: {
        if (!fields.debtRange) {
          setError("Please select an option")
          return false
        } else {
          setError()
          return true
        }
      }
      default:
        return true
    }
  }

  const renderNetlifyForm = () => {
    const renderInputs = () => {
      const inputs = []
      for (const key in fields) {
        inputs.push({ name: key, value: fields[key] })
      }
      return inputs.map(node => (
        <input
          key={node.name}
          type="text"
          value={node.value}
          readOnly
          name={node.name}
        />
      ))
    }
    return (
      <form
        onSubmit={handleSubmit}
        // action="/inquire/received"
        // method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        id="inquire-netlify-form"
        name="Inquire"
      >
        {renderInputs()}
        <button id="inquire-netlify-form-submit" type="submit" />
      </form>
    )
  }

  return (
    <FormContext.Provider value={{ fields, onFieldChange }}>
      {<div style={{ display: "none" }}>{renderNetlifyForm()}</div>}
      {formOpen && (
        <div className="form">
          <div className="form__overlay" onClick={() => toggleForm(false)} />
          <div className="form__container">
            {renderFormState()}
            {error && (
              <Typography
                variant="body1"
                style={{ color: "red", textAlign: "center", marginTop: 12 }}
              >
                {error}
              </Typography>
            )}
            <div className="form__controls">
              {showPrev && (
                <Button onClick={onPrev} variant="outline">
                  Previous
                </Button>
              )}
              {showNext && (
                <Button onClick={onNext} variant="primary">
                  Next
                </Button>
              )}
              {showSubmit && (
                <Button onClick={onSubmit} variant="primary">
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              )}
              {showDone && (
                <Button onClick={onDone} variant="primary">
                  Done
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </FormContext.Provider>
  )
}

export default Form
