import React from "react"
import "./footer.css"
import Typography from "./typography"
import Button from "./button"
import { Link } from "gatsby"
import { LayoutContext } from "./layout"

const Footer = () => {
  const { toggleForm } = React.useContext(LayoutContext)
  return (
    <footer className="footer">
      <img
        src={require("../images/footer.svg")}
        alt="Waves"
        className="footer__bg"
      />
      <div className="two-col page-wrapper footer__upper-container">
        <div className="footer__cta">
          <Typography variant="h2">
            Achieve your goals, improve your quality of life, and grow your net
            worth.
          </Typography>
          <Button onClick={() => toggleForm(true)} variant="primary">
            Get Started
          </Button>
        </div>
        <div className="footer__links">
          <Link to="/how-it-works" className="footer__link">
            How it works
          </Link>
          <Link
            to="/work-with-us"
            activeClassName="active"
            className="footer__link"
          >
            Work with us
          </Link>
          <Link to="/blog" activeClassName="active" className="footer__link">
            Articles
          </Link>
          <Link
            to="/privacy-policy"
            activeClassName="active"
            className="footer__link"
          >
            Policy
          </Link>
        </div>
      </div>
      <div className="two-col page-wrapper footer__lower">
        <div className="logo">
          <img src={require("../images/logo.png")} alt="Logo" />
        </div>
        <div className="copyright">
          © {new Date().getFullYear()}. All Rights Reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer
